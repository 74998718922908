import React from 'react';

import { Base } from '../../components/common';
import SEO from '../../components/seo';

import LoginAndRedirect from '../../components/login-with-secret/LoginAndRedirect';
import withLocation from '../../hoc/withLocation';

import SectionArc from '../../components/common/SectionArc';

import HeadTitle from '../../components/common/HeadTitle';
import OfferingItems from '../../components/common/OfferingItems';
import NewsletterSection from '../../components/first/sections/NewsletterSection';
import BooksSection from '../../components/first/sections/BooksSection';
import IntroSection from '../../components/first/sections/IntroSection';
import TestimonialSection from '../../components/first/sections/TestimonialSection';
import OfferingSection from '../../components/common/sections/OfferingSection';
import Emoji from '../../components/common/Emoji';
import TopMessage from '../../components/first/TopMessage';
import VideoRow from '../../components/first/VideoRow';

const CampaignPage = ({ search }) => {
  const { secret, target } = search;
  if (secret) {
    return <LoginAndRedirect stay {...{ secret, target: target || 'kampanjer/sportlov2021' }} />;
  }

  const days = 30;
  const ctaText = <>Prova gratis i {days}&nbsp;dagar!</>;
  const ctaUrl = '/signup?code=sport2021';

  return (
    <Base ctaUrl={ctaUrl}>
      <SEO title="Välkommen" />

      <IntroSection
        {...{ ctaText, ctaUrl }}
        title={<HeadTitle>ÄNTLIGEN SPORTLOV</HeadTitle>}
        topMessage={
          <TopMessage>
            Upptäck barnböckernas magiska värld med Boksnok - över 1000 barnböcker på ett och samma
            ställe <Emoji label="skidor" value="⛷⛷" />
          </TopMessage>
        }
        videoRow={
          <VideoRow>
            <OfferingItems darkMode emphasizeDays={days} />
          </VideoRow>
        }
      />

      <SectionArc topColor="purple" bottomColor="white" />
      <BooksSection bgColor="white" />
      <SectionArc topColor="white" bottomColor="green" flipped />
      <TestimonialSection bgColor="green" />
      <OfferingSection
        {...{ ctaText, ctaUrl }}
        buttonCentered
        buttonHalf={false}
        bgColor="blue-dark"
        offeringItems={<OfferingItems emphasizeDays={days} />}
      />
      <SectionArc topColor="blue-dark" bottomColor="white" />
      <NewsletterSection bgColor="white" />
    </Base>
  );
};

export default withLocation(CampaignPage);
